html {
  font-family: system-ui, BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background: #000;
  color: #fff;
}

body {
  margin: 0;
  padding: 0 3rem;
  min-height: 100vh;
  text-align: center;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

.logo {
  margin: 3rem 0;
  width: 50px;
}

.title {
  font-family: monospace;
  font-size: 3em;
  margin: 1em;
  height: 2em;
  word-break: break-all;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.polis-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  font-size: 1.25rem;
  list-style: none;
  width: 100%;
  max-width: 800px;
  transition: transform 0.5s ease;
}

.polis-list__item {
  flex: 1;
  transition: all 0.5s ease;
}

.polis-list__item + .polis-list__item {
  margin-top: -1px;
}

.polis-list__city {
  display: block;
  text-align: center;
  padding: 1em;
  text-decoration: none;
  background: #000;
  border: 1px solid #fff;
}

@media (min-width: 550px) {
  .logo {
    width: 100px;
  }

  .polis-list {
    flex-direction: row;
  }

  .polis-list__item {
    flex-basis: 50%;
  }

  .polis-list__item + .polis-list__item {
    margin-top: 0;
  }

  .polis-list__item:nth-child(even) {
    margin-left: -1px;
  }

  .polis-list__item:nth-child(n + 3) {
    margin-top: -1px;
  }
}

@media (min-width: 550px) and (pointer: fine) {
  .polis-list:hover {
    transform: scale(0.85);
  }

  .polis-list:hover .polis-list__item {
    filter: brightness(0.5);
  }

  .polis-list__item:hover {
    transform: scale(1.45);
    z-index: 100;
    box-shadow: 0 0 1em 1em rgba(0, 0, 0, 0.75);
    filter: brightness(1) !important;
  }
}

@media (min-width: 800px) {
  body {
    font-size: 24px;
  }
}

@media (prefers-color-scheme: light) {
  html {
    background: #fff;
  }

  body {
    filter: invert();
  }
}
